import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`  
  
  :root {
    /* color-scheme: light dark; */
    --reach-dialog: 1;
  }

  * {
    box-sizing: border-box;
    font-family: 'Sora', sans-serif;
    font-size: 0.75rem;
    margin: 0;
    outline: none;
    padding: 0;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: antialiased;
  }

  html {
    width: 100%;
    transition: color 1s ease-in-out;
  }

  a {
    text-decoration: none;
  }

  p, li, ol, span {
    margin:  0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 10px 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul ul {
    margin: 0;
  }

  li, ol {
    list-style: none;
  }

  label, input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
  }

  input, select {
    display: block;
    width: 100%;
    /* height: calc(1.5em + .75rem + 2px); */
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  #root {
    width: 100%;
    min-height: 100vh;
  }

  .print-source {
    display: none;
  }

  body > .print-source {
    display: block;
  }

  @media print {
    .print-source {
      display: block;
    }
  }

  /* @media (prefers-color-scheme: dark) {
    img {
      filter: invert(100) hue-rotate(180deg);
    }
    
    html {
      filter: invert(100) hue-rotate(180deg);
      background-color: black !important;
      color: white !important;
    }
  } */





  .w3samples_table_loader {
    background-color: #FFF;
    color: #666;
    width: 100%;
    padding: 0;
}
.w3samples_table_loader th{
    background: #F7F9FA;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 12px 10px;
	text-align: left;
}
.w3samples_table_loader td{
      border-top: 0px solid #E5E5E5;
      border-bottom: 1px solid #E5E5E5;
      padding: 12px 10px;
	  vertical-align: top;
}
.w3samples_table_loader span{
width: calc(100% - 5px);
    height: 15px;
    border-radius: 3px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-lines-table 1.6s infinite linear;
    display: inline-block;
    min-width:15px;
}
.w3samples_table_loader span.sub-temp{
	width: calc(100% - 50%);
	margin-top: 5px;
}
.w3samples_table_loader span.sub-temp-three{
	width:calc(100% - 70%);
	display:block
}
.col1{
		width: 25px;
}
.col2{
		width: 60px;
}
.col3{
		width: 45px;
}
.col4{
		width: 344px;
}
.col5{
		width: 145px;
}
.col6{
		width: 113px;
}
.col7{
		width: 73px;
}
.col8{
		width: 160px;
}
.col9{
		width: 92px;
}



@keyframes shine-lines-table {
  0% {
	opacity:0.4;
  }
  50%{
	opacity:0.8;
  }
  100% {
	opacity:0.4;
  }
}
`
