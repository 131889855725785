import { DefaultTheme } from 'styled-components'
import { calculateRem } from '../util/convert-units'

export const color = {
  alabaster: '#f9f9f9',
  black: '#000000',
  blue: '#31468a',
  gray54: '#8a8a8a',
  gray60: '#999',
  gray81: '#cfcfcf',
  gray85: '#858585',
  gray93: '#ededed',
  gray95: '#f2f2f2',
  gray97: '#F7F7F7',
  orange: '#F15922',
  red: '#fe5d70',
  white: '#FFFFFF',
}

const button = {
  icon: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: color.white,
    fontSize: calculateRem(20),
    margin: 0,
  },
  primary: {
    backgroundColor: color.blue,
    borderColor: color.blue,
    color: color.white,
    fontSize: calculateRem(20),
    hover: {
      backgroundColor: '#0069d9',
      borderColor: '#0069d9',
      color: color.white,
    },
  },
  secondary: {
    backgroundColor: color.gray85,
    borderColor: color.gray85,
    color: color.white,
    fontSize: calculateRem(20),
    hover: {
      backgroundColor: '#6C6C6C',
      borderColor: color.white,
      color: color.white,
    },
  },
}

const card = {
  backgroundColor: color.white,
  borderColor: '#e8e8e8',
  boxShadowColor: '#efefef',
  header: {
    color: color.orange,
    fontSize: calculateRem(30),
  },
  separator: {
    backgroundColor: '#ccc',
  },
}

const font = {
  color: color.gray60,
  fontFamily: "'Roboto Condensed', sans-serif",
  primary: color.blue,
}

const form = {
  default: {
    borderColor: color.gray81,
    color: color.blue,
    focus: {
      borderColor: color.gray85,
    },
  },
  error: {
    borderColor: color.red,
    color: color.red,
    focus: {
      borderColor: color.red,
    },
  },
}

const header = {
  backgroundColor: color.blue,
  boxShadowColor: color.gray81,
  size: 65,
}

const icon = {
  back: color.blue,
  buyerOrderAuthorized: '#26fb3b',
  buyerOrderIssued: '#a2a3a4',
  buyerOrderRefused: '#ff8d0c',
  buyerOrderUnderAnalysis: color.blue,
  chevronLeft: '#888888',
  chevronRight: '#888888',
  edit: '#888888',
  print: color.blue,
  rubbish: '#FF4343',
  view: '#a2a3a4',
}

const pagination = {
  fontSize: calculateRem(12),
  pageSize: {
    backgroundColor: '#f5f5f5',
    fontSize: calculateRem(10),
  },
}

const slider = {
  item: {
    active: {
      background: '#ccc',
      color: '#333',
      fontWeight: 600,
    },
    background: '#EBEBEB',
    color: color.gray85,
    hover: {
      background: color.white,
    },
  },
}

const table = {
  backgroundColor: 'initial',
  borderColor: '#ddd',
  cell: {
    data: calculateRem(16),
    header: calculateRem(12),
  },
  color: '#333',
  fontSize: calculateRem(12),
  hover: {
    backgroundColor: '#f5f5f5',
    color: '#333',
  },
}

export const mainTheme: DefaultTheme = {
  button,
  card,
  font,
  form,
  header,
  icon,
  pagination,
  slider,
  table,
}
