import Keycloak from 'keycloak-js'
import { KeycloakCredentials } from './keycloak-credential'

const keycloakCredentials: KeycloakCredentials = JSON.parse(process.env.REACT_APP_KEYCLOAK_JSON!)
const realm = keycloakCredentials.realm
const authServerUrl = keycloakCredentials['auth-server-url']

export const keycloak = Keycloak({
  url: authServerUrl,
  realm: realm,
  clientId: keycloakCredentials['resource'],
})

export const keycloakConfig = {
  onLoad: 'check-sso',
}

export const keycloakLinks = {
  accountConsole: `${authServerUrl}realms/${realm}/account`,
  adminConsole: `${authServerUrl}admin/${realm}/console`,
}