import { ReactKeycloakProvider } from '@react-keycloak/web'
import { StrictMode, Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from './theme/global-style'
import { mainTheme } from './theme/main-theme'
import reportWebVitals from './reportWebVitals'
// import { StateProvider } from './state'
import { keycloak, keycloakConfig } from './util/auth'
// import { initialState, reducers } from './reducers';

const App = lazy(() => import('./screen/app'))


ReactDOM.render(
  <StrictMode>
    <GlobalStyle />
    <Suspense fallback="Loading">
      <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakConfig}>
        <ThemeProvider theme={mainTheme}>
          {/* <StateProvider initialState={initialState} reducer={reducers}>ƒ */}
          <App />
          {/* </StateProvider> */}
        </ThemeProvider>
      </ReactKeycloakProvider>
    </Suspense>,
  </StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
